.ant-list {
    min-height: 400px !important;
}

.site-navigation-steps {
    margin-bottom: 60px;
    box-shadow: 0px -1px 0 0 #e8e8e8 inset;
}

.ant-descriptions-item-label {
    font-weight: bold;
}

.ant-descriptions-bordered .ant-descriptions-item-label, .ant-descriptions-bordered .ant-descriptions-item-content {
    border: 1px solid #cccccc;

}

.ant-descriptions-bordered .ant-descriptions-item-label:last-child, .ant-descriptions-bordered .ant-descriptions-item-content:last-child {
    border-right: 1px solid #cccccc;
}

.ant-list-items{
    height: 750px;
}