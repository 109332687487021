/* win 1920 */
@media screen and (max-width: 3000px) and (min-width: 2000px) {
   .workbench-content{
      height:79vh
   }
}


/* mac 1680 */
@media screen and (max-width: 2000px) and (min-width: 1500px) {
    .workbench-content{
        height:75vh
     }
}

/* 联想 1366 */
@media screen and (max-width: 1500px) and (min-width: 200px) {
    .workbench-content{
        height:70vh
     }
}