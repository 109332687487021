.month-index-wapper{
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0 2px 8px rgba(0,0,0,0.15);
}
/* 标题区域 */
.month-title-wapper{
    display: flex;
    padding: 0 30px;
    justify-content: space-between;
    border-bottom: 1px solid #dbe5ed;
    align-items: center;
    line-height: 50px;
}
/* 标题卡片看板 */
.month-title-card-wapper{
    width: inherit;
    display: flex;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    margin: 0 30px;
    /* border-right: 1px solid #dbe5ed; */
    margin-bottom: 20px;
}

.month-table-wapper{
    padding: 10px 30px;
}

.month-table-wapper * {
    padding: 0px 2px!important;
}

.get_money_percent_input .ant-input-number-input{
    height: 25px;
}

/* win 1920 */
@media screen and (max-width: 2100px) and (min-width: 1500px) {

}

/* 联想 1366 */
@media screen and (max-width:1500px) and (min-width:200px){
    .month-index-wapper{
        margin: 2px 40px!important;
    }
    .month-title-card-wapper{
        margin-bottom: 10px;
    }
}

