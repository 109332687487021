.positionIndexWapper {
    background: white;
    max-width: 1400px;
    min-height: calc(100vh - 41px);
    margin: 0 auto;
    padding: 20px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.controllButtons {
    display: flex;
    gap: 10px;
    align-items: center;
}

.controlBar {
    min-height: 40px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.controlBarLeft {
    display: flex;
    align-items: baseline;
    gap: 5px;
}

.controlBarRight {
    display: flex;
    align-items: center;
    gap: 3px;
}

.searchFrom {
    padding: 20px 0;
}

.rowExpandable {
    padding: 10px;
}
