.ant-popover-inner-content {
    overflow: auto;
}

.ant-list-items {
    height: auto;
}

.ant-list-item {
    padding: 3px 0;
}