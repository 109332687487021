.fc .fc-daygrid-day-number {
    color: black;
}

.fc .fc-col-header-cell-cushion{
    color: black;
}

.fc-daygrid-dot-event .fc-event-title{
    color: black;
}

.fc-h-event .fc-event-title-container{
    height: 2px;
}