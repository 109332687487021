.letter-card-wapper-index{
    width: 100%;
}

.letter-card-wapper-index>.ant-badge {
    width: 100%;
}

.letter-card-wapper {
    background-color: 0px 20px 50px 0px rgba(0, 0, 0, 0.08);
    width: 100%;
    padding: 20px 30px;
    box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    display: grid;
    grid-template-areas: "avatar name time" "avatar status button" "message message message" "content content content";
    transition: 0.4s;
    grid-template-columns: 64px 1fr 1fr auto;
    row-gap: 10px;
    align-items: center;
    cursor: pointer;
}

.letter-card-wapper:hover {
    box-shadow: 0px 26px 70px 0px rgba(0, 0, 0, 0.1);
}

.letter-active{
    
}

.letter-card-wapper:hover.letter-card-wapper::after,.letter-card-wapper:hover.letter-card-wapper::before {
    display: block;
    content: "";
    position: absolute;
    height: 100%;
    left: 0;
}

.letter-card-wapper:hover.letter-card-wapper::before {
    width: 8px;
    background: linear-gradient( 180deg, rgba(142, 197, 242, 1) 0%, rgba(79, 157, 222, 1) 0%);
}

.letter-card-wapper:hover.letter-card-wapper::after {
    width: 16px;
    opacity:0.6;
    background: linear-gradient( 180deg, rgba(142, 197, 242, 1) 0%, rgba(79, 157, 222, 1) 0%);
}

.name {
    grid-area: name;
}

.avatar {
    grid-area: avatar;
}
.status{
    grid-area: status;
}
.time {
    grid-area: time;
    justify-self: end;
}
.read-button{
    grid-area: button;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.content{
    grid-area: content;
}
.letter-card-wapper > .message >div.ant-typography, .ant-typography p {
    margin-bottom: 0em!important;
}
.message {
    grid-area: message;
    display: grid;
    grid-template-columns: 1fr 30px;
    align-items: center;
}

.letter-card-detail{
    justify-self: end;
}


