.targetCardWapper{
    /* width: 380px; */
    min-height: 100px;
    /* flex-shrink: 0; */
    border:1px solid #fff;
    background: white;
    padding: 20px;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: space-around;
}

.dashboard{

}
.leftArea{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.leftLegend{
    display: flex;
    justify-content: space-around;
    gap: 5px;
}

.payLegend ,.dateLegend{
    position: relative;
}
.dateLegend:after,.payLegend:after{
    position: absolute;
    content: '';
    width: 20px;
    height: 5px;
    border-radius: 2.5px;
    background: rgb(24,144,255);
    left: 50%;
    bottom: -5px;
    transform: translate(-50%);
}

.dateLegend:after{
    background: rgb(250,173,20);
}
.rightArea{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
}
.titleRow{
    display: flex;
    gap: 10px;
    align-items: baseline;
}

.titleText{
    font-size: 0.6vw;
    font-weight: bold;
    display:flex
}

.lastTitleText{
    font-size: 0.6vw;
}

.yearText{
    display: flex;
    align-items: center;
    font-size: 0.6vw;
    margin-left: 10px;
}

.valueRow{
    display: flex;
    gap: 20px;
    font-size: 0.6vw;
}

.dashboard{
    position: relative;
}
.progressCircle{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}

.targetPercentContent{
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* win 1920 */
@media screen and (max-width: 2100px) and (min-width: 1700px) {
    .targetRow{
        grid-template-columns: repeat(3,1fr);
    }
    .titleText{
        font-size: 1vw;
    }
    .lastTitleText{
        font-size: 0.9vw;
    }
    .yearText{
        font-size: 0.8vw;
    }
    .valueRow{
        font-size: 0.8vw;
    }
}


/* mac 1680 */
@media screen and (max-width: 1700px) and (min-width: 1500px) {
    .targetRow{
        grid-template-columns: repeat(3,1fr);
    }
    .titleText{
        font-size: 1vw;
    }
    .lastTitleText{
        font-size: 0.9vw;
    }
    .yearText{
        font-size: 0.8vw;
    }
    .valueRow{
        font-size: 0.8vw;
    }
}

/* mac 1680 */
@media screen and (max-width: 1500px) and (min-width: 1200px) {
    .targetRow{
        grid-template-columns: repeat(3,1fr);
    }
    .titleText{
        font-size: 1vw;
    }
    .lastTitleText{
        font-size: 0.9vw;
    }
    .yearText{
        font-size: 0.8vw;
    }
    .valueRow{
        font-size: 0.8vw;
    }
}

/* 联想 1366 */
@media screen and (max-width: 1200px) and (min-width: 800px) {
    .targetRow{
        grid-template-columns: repeat(2,1fr);
    }
    .titleText{
        font-size: 1vw;
    }
    .lastTitleText{
        font-size: 0.9vw;
    }
    .yearText{
        font-size: 0.8vw;
    }
    .valueRow{
        font-size: 0.8vw;
    }
}


/* 联想 1366 */
@media screen and (max-width: 800px) and (min-width: 200px) {
    .targetRow{
        grid-template-columns: repeat(1,1fr);
    }
    .titleText{
        font-size: 1vw;
    }
    .lastTitleText{
        font-size: 0.9vw;
    }
    .yearText{
        font-size: 0.8vw;
    }
    .valueRow{
        font-size: 0.8vw;
    }
}