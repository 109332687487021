.introduction{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 5px;
}
/* mac 2560 */
@media screen and (max-width: 3000px) and (min-width: 1700px) {
    .introduction {
        font-size: 30px;
    }
}
/* mac 1680 */
@media screen and (max-width: 1700px) and (min-width: 1500px) {
    .introduction {
        font-size: 18px;
    }
}

/* 联想 1366 */

@media screen and (max-width: 1400px) and (min-width: 800px) {
    .introduction {
        font-size: 15px;
    }
}