/* 整体 */
.wholeDiv {
    align-items: center;
    text-align: center;
    padding: 20px 20px 0px 20px;
}

/* 查询条件 */
.queryConditionsDiv {
    padding: 0px 0px 0px 0px;
}

.item {
    padding: 0px 0px 5px 0px;
}

/* 统计维度 */
.dimensionDiv {
    width: 100%;
    padding: 20px 0px 0px 0px;
}

.checkboxGroupDiv {
    display: grid;
    grid-template-columns: 100px 1fr;
}

/* 表格数据 */
.tableDiv {
    padding: 20px 0px 0px 0px;
}