/* 卡片容器布局 */
.cursorItemWapper{
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-shrink: 0;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    transition: all 0.2s;
    /* counter-increment: item-count; */
    position: relative;
}
/* 子元素计数 */
/* .cursorItemWapper::after{
    position: absolute;
    width: 20px;
    height: 20px;
    background: black;
    color: white;
    text-align: center;
    top: 5px;
    left: 5px;
    content: counter(item-count);
} */

.cursorItemWapper:hover{
    z-index: 100000;
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.2),
    0px -5px 20px rgba(0, 0, 0, 0.2);
}

/* 卡片上面区域布局样式 */
.cursorUpErea{
    width: 100%;
    display: grid;
    grid-template-areas: "cursor-type"
    "cursor-title"
    "cursor-tags"
    ;
    padding: 10px;
    grid-template-rows: 40px 1fr 30px;
    align-items: center;
    background-repeat: no-repeat!important;
    background-position: center!important;
    background-size: cover!important;
}

/* 第一行样式 */
.cursorUpRowFirst{
    grid-area: cursor-type;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


/* 课程类别样式 */
.cursorTypeText{
    color:white!important;
    padding: 0 3px;
    font-weight: 600;
    border: 3px solid #fff;
    border-radius:20px;
    text-align: center;
    cursor: default;
}

/* 课程人数样式 */
.cursorPeople{
    color:#fff;
    font-size: 1rem;
    font-weight: 500;
    cursor: default;
}

.upMiddleRow{
    grid-area: cursor-title;
    display: flex;
    flex-direction: column;
}
/* 课程标题样式 */
.cursorTitle{
    color: #fff!important;
    cursor: pointer;
    position: relative;
    border-bottom-style:solid;
    border-bottom-color:white;
    border-bottom-width: 0px;
    transition: all 0.5s;
}

.cursorTitle::after{
    content: '';
    position: absolute;
    bottom: -1px;
    left:0;
    height: 3px;
    width: 0;
    /* background: #fff; */
    border-radius: 1.5px;
    background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
}
.cursorTitle:hover::after{
    animation: lineGo 500ms ease;
    animation-fill-mode: forwards;
}

@keyframes lineGo{
    0%{
        width: 0px; 
    }
    100%{
        width: 100%; 
    }
}
/* 开课时间 */
.cursorStartTime{
    margin-top: 2px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: #f6f6f6!important;
    cursor: default;
}

/* 上课形式 */
.teachTypeText{
    font-size: .6rem;
    margin-left: 5px;
    border: 1px solid #fff;
    height: 20px;
    line-height: 18px;
    border-radius: 10px;
    padding:0 5px;
}

/* 卡片上部标签容器布局 */
.cursorTagsWapper{
    grid-area: cursor-tags;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

/* 标签样式 */
.cursorTag{
    color: white!important;
    margin-right: 9px;
    cursor: default;
}


/* 卡片下部布局 */
.cursorDownErea{
    display: grid;
    width: 100%;
    height: 100%;
    padding: 10px;
    grid-template-rows: 50px 1fr 1fr;
    align-items: center;
    background: #fff;
    position: relative;
}

/* 无开课时间 */
.withoutOpentime{
    grid-template-rows: 1fr 50px;
    align-items: initial;
}

/* 课程介绍样式 */
.cursorIntroduce{
    margin-bottom: 0!important;
    cursor: default;
}

/* 课程时间 */
.cursorTimeErea{
    cursor: default;
}

/* 是否可蹭课 */
.isAttendText{
    padding: 0 2.5px;
    margin: 0 2.5px;
    border-left: 2px solid rgba(0,0,0,0.2);
}

/* 老师行布局 */
.cursorTeacher{
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: default;
}

.cursorTeacherWapper{
    display: flex;
    align-items: center;
}

/* 老师前缀样式 */
.cursorTeacherPrefix{
    display: flex;
}

.cursorTeacherSuffix{

}

.cursorTeacherPrefix{

}

/* 动作按钮定位 */
.actionButton{
    position: absolute!important;
    bottom: 10px!important;
    right: 10px;
}

.actionButton:hover{
    color: #fff;
}
.actionButton:focus{
    color: #fff;
}

/* 动作按钮变体样式 */
.otherButton{
    color: #fff!important;
    border-radius: 20px!important;
    bottom: 15px;
}