.marke-border{
    /* border: 1px solid rgb(0, 0, 0); */
    /* box-shadow: 0 2px 8px rgba(0,0,0,0.15);  */
    /* background:rgba(255,255,255) ; */
    /* padding: 1rem;
    border-radius: .5rem; */
}

.marke-border-title{
    font-size: 1.8rem;
    font-weight: 600;
    border-bottom: 1px solid rgb(20, 20, 20,0.1);
    display: flex;
    justify-content: space-between;
}

.ant-table-title{
    padding: 1rem 0;
    font-size: 1.1rem;
}