.card {
    display: flex;
    flex-direction: column;
}

.card:hover {
    cursor: pointer;
}

.card_img {
    padding: 10px;
}