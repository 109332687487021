.goodsIndexWapper {
    min-height: 90vh;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.controlBar {
    min-height: 40px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    background: white;
    position: fixed;
    top: 5;
    z-index: 100;
    width: calc(100% - 40px);
    border-bottom: 2px solid #ccc;
}

.controlBarLeft {
    display: flex;
    align-items: baseline;
    gap: 5px;
}

.controlBarMiddle{
    width: 20vw;
    max-width: 300px;
    align-self: center;
    min-width: 200px;
}

.controlBarRight {
    display: flex;
    align-items: center;
    gap: 3px;
}

.DataArea {
    padding: 0px 20px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    display: flex;
    background: white;
    gap: 10px;
    flex-direction: column;
}

.itemMainImg {
    width: 145px;
    height: 145px;
}

.itemMainImg:hover {
    transform: none;
}

.itemInfo {
    display: flex;
    padding: 10px;
    /* gap: 10px; */
    align-items: center;
    border: 2px dashed rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    position: relative;
    margin-top: 50px;
}

.noData{
    font-size: x-large;
    font-weight: 600;
    min-height: max-content;
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
}

.itemAttrs {
    list-style: none;
    padding: 0 20px 18px;
}

.itemSecond {
    color: rgba(0, 0, 0, 0.5);
}

.itemSecondTitle {
    padding: 10px 20px;
    font-weight: 600;
    font-size: 15px;
}

.attr {
    list-style: none;
    float: left;
    width: 25%;
    margin: 5px 0;
    /* 文字省略号 */
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.sale{
    display: flex;
    width: 100%;
}

.saleTable *{
    padding: 2px!important;
}
.chart {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 20px;
    min-height: calc(100vh - 400px);
}

.topIndex{
    overflow: scroll;
    border-right: 1px solid rgba(0, 0, 0, 0.08);
    max-height:  calc(100vh - 400px);
    position: relative;
}

/* BFC */
.topIndex ul{
    overflow: hidden;
}

/* 伪元素添加 */
.topIndex::after{
    /* 必须 */
    content: "搭配排行";
    position: absolute;
    right: 10px;
    top: -3px;
    height: 100%;
    text-align: center;
    font-size: 1.33em;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.7);
    letter-spacing: 2px;
}

.topRow {
    list-style: none;
    display: flex;
    gap: 20px;
    border: 2px dashed rgba(0, 0, 0, 0.08);
    padding: 20px 10px;
    margin:10px 5px 0;
    border-radius: 10px;
    position: relative;
    width: max-content;
    /* 浮动布局 */
    float: left;
}
.topRowTitle{
    position: absolute;
    z-index: 10;
    left: 0;
    font-size: 16px;
    text-align: center;
    top: -10px;
    left: -15px;
    font-weight: 600;
    color: white;
    background: slateblue;
    cursor: pointer;
    padding: 2px;
    border:2px ridge ;
    border-radius: 16px;
    transform: scale(.8);
}

.goodItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.topRow .goodItem:nth-child(n):not(:last-child):after{
    position: absolute;
    content: "+";
    right: -15px;
    font-size: 16px;
    font-weight: 600;
    top: 50%;
    transform: translateY(-50%);
    color: rgba(0, 0, 0, 0.5);
}

.goodItem img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
}

.goodItem text {
    /* display: inline-block; */
    width: 100px;
    font-size:9px;
    color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
}
img{
    object-fit: cover;
    transition: all .1s;
}
/* 加载成功的图片 */
.topIndex img:not([data-src]){
    border-radius: 50%;
}

.imgText{

}

/* 未加载及加载失败的图片 */
.topIndex img[data-src],.topIndex img:not([src]),.itemMainImg:not([src]){
    border-radius: 50%;
    border:2px solid rgba(0, 0, 0, 0);
    /* 去除img未加载的黑边 */
    padding: 40px;
    /* 渐变背景 */
    background: linear-gradient(
        100deg,
        rgba(255,255,255,0) 40%,
        rgba(255,255,255,0.5) 50%,
        rgba(255,255,255,0) 60%
    ) #ededed;
    background-size: 200% 100%;
    background-position: 120%;
    animation: loading 1s ease-in-out infinite;
    transform: none;
}

/* 图片加载动画 */
@keyframes loading {
    to{
        background-position: -20%;
    }
}

.itemMainImg:not([src]){
    padding: 70px;
    border-radius: 10%;
}


.noSkus {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
}

.noText {
    color: rgba(0, 0, 0, 0.5);
}

.CustomCollapse span {
    padding-bottom: 10px !important;
}