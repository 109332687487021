/* 包裹容器 */
.cardWapper{
    background: white;
    padding: 10px 8px;
    /* display: flex; */
    flex-direction: column;
    justify-content: space-between;
    width: 200px;
    height: 120px;
    flex-shrink: 0;
    border-left: 1px solid #dbe5ed;
    border-bottom: 1px solid #dbe5ed;
    position: relative;
    transition: all 0.3s;
    overflow: hidden;
    cursor: default;
}
/* 最后一个子元素加上右边框 */
.cardWapper:last-child{
    border-right: 1px solid #dbe5ed;
}
.cardWapper:hover{
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    z-index: 10;
}
.cardWapper::after{
    transition: all 0.3s;
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #2062e6;
}

.compareRow{
    display: flex;
    justify-content: space-between;
    align-items: center;
}


/* win 1920 */
@media screen and (max-width: 2100px) and (min-width: 1700px) {
    .cardWapper{
        width: 150px;
    }
}


/* mac 1680 */
@media screen and (max-width: 1700px) and (min-width: 1500px) {
    .cardWapper{
        width: 150px;
    }
}

/* mac 1680 */
@media screen and (max-width: 1500px) and (min-width: 1200px) {
    .cardWapper{
        width: 150px;
    }
}

/* 联想 1366 */
@media screen and (max-width: 1200px) and (min-width: 800px) {
    .cardWapper{
        width: 150px;
    }
}


/* 联想 1366 */
@media screen and (max-width: 800px) and (min-width: 200px) {
    .cardWapper{
        width: 150px;
    }
}